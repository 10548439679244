import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import PersonIcon from '@material-ui/icons/Person'
import Avatar from '@material-ui/core/Avatar'
import { blue } from '@material-ui/core/colors'

import { actions } from './../../redux'
const { changeEventDetails, changeStep } = actions

const styles = theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
})

class ProductSelect extends React.Component {
  render() {
    const { product_types, classes } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
        style={{ minWidth: '350px' }}
      >
        <Grid item xs={12}>
          <List>
            {product_types.map(product => (
              <ListItem
                button
                key={product.ID}
                onClick={() => this.props.setProductID(product)}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={product.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  product_types: state.common.product_types.filter(p => p.showInCalendar),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setProductID: product => {
    dispatch(changeEventDetails({ field: 'product_type', value: product.name }))
    dispatch(
      changeEventDetails({ field: 'product_type_id', value: product.ID })
    )
    dispatch(
      changeEventDetails({ field: 'product_type_object', value: product })
    )
    dispatch(changeStep(1))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductSelect))
