import React from 'react'
import { connect } from 'react-redux'
import { actions } from './../../redux'
import FastTextField from 'common/components/FastTextField'
import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  Divider,
  Typography,
} from '@material-ui/core'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import { searchCustomers } from 'customer/actions'
import { IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createTrialForCustomer } from 'calendar/actions'
export const genders = [{ label: 'Boy' }, { label: 'Girl' }, { label: 'Other' }]

class CreateTrialDialog extends React.Component {
  render() {
    const { open, handleClose, customers, classInfo } = this.props
    console.log(classInfo)
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Add FreeTrial
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Tabs
              value={this.props.tab}
              onChange={(e, val) => this.props.changeTabTrial(val)}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab value="existing" label="Existing customer" />
              <Tab value="new" label="New customer" />
            </Tabs>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={4}
            style={{ minWidth: '450px' }}
          >
            {this.props.tab === 'existing' && (
              <Grid item xs={12}>
                <p>Select customer</p>
                <Autocomplete
                  freeSolo
                  options={customers}
                  getOptionLabel={option =>
                    `${option.first_name} ${option.last_name} (${option.email})`
                  }
                  renderOption={option => (
                    <p>
                      {option.first_name} {option.last_name}{' '}
                      {option.hubspot && option.hubspot.gender && (
                        <span>({option.hubspot.gender})</span>
                      )}
                      <br />
                      <span>
                        email=
                        {option.email}
                      </span>
                      {option.hubspot && option.hubspot.phone && (
                        <span>
                          <br />
                          phone=
                          {option.hubspot.phone}
                        </span>
                      )}
                      {option.hubspot && option.hubspot.state && (
                        <span>
                          <br />
                          state=
                          {option.hubspot.state}
                        </span>
                      )}
                    </p>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Customer"
                      variant="outlined"
                      fullWidth
                      onChange={e => this.props.searchCustomers(e.target.value)}
                    />
                  )}
                  value={this.props.customer}
                  onChange={(e, c) =>
                    this.props.updateFreeTrialData('customer', c)
                  }
                />
              </Grid>
            )}
            {this.props.tab === 'new' && (
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  style={{ textAlign: 'center' }}
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <FastTextField
                      label="First name"
                      fullWidth
                      value={this.props.first_name}
                      onChange={e =>
                        this.props.updateFreeTrialData(
                          'first_name',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastTextField
                      label="Last name"
                      fullWidth
                      value={this.props.last_name}
                      onChange={e =>
                        this.props.updateFreeTrialData(
                          'last_name',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastTextField
                      label="Email"
                      fullWidth
                      value={this.props.email}
                      onChange={e =>
                        this.props.updateFreeTrialData('email', e.target.value)
                      }
                      disabled={
                        this.props.no_create || this.props.tab === 'existing'
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastTextField
                      label="Phone"
                      fullWidth
                      value={this.props.phone}
                      onChange={e =>
                        this.props.updateFreeTrialData('phone', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <span style={{ fontWeight: 'bold', color: '#03A9F4' }}>
                      Child Details
                    </span>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <FastTextField
                      label="First name"
                      fullWidth
                      value={this.props.first_name}
                      onChange={e =>
                        this.props.updateFreeTrialData(
                          'child_first_name',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastTextField
                      label="Last name"
                      fullWidth
                      value={this.props.child_last_name}
                      onChange={e =>
                        this.props.updateFreeTrialData(
                          'child_last_name',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Child Birthdate
                    <DatePickerComponent
                      id="child_birthdate"
                      placeholder="Child Birthdate *"
                      value={this.props.child_birthdate}
                      onChange={e =>
                        this.props.updateFreeTrialData(
                          'child_birthdate',
                          e.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      options={genders}
                      placeholder="Select gender *"
                      value={genders.find(
                        f => f.label === this.props.child_gender
                      )}
                      onChange={e =>
                        this.props.updateFreeTrialData('child_gender', e.label)
                      }
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              this.props.createTrial()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.calendar.trial_data,
  customers: state.customer.customers,
  classInfo: state.calendar.event_details.class,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.editTrialData({ key: 'open', value: false })),
  updateFreeTrialData: (k, v) =>
    dispatch(actions.editTrialData({ key: k, value: v })),
  searchCustomers: q => dispatch(searchCustomers(q)),
  changeTabTrial: tab => dispatch(actions.changeTabTrial(tab)),
  createTrial: () => dispatch(createTrialForCustomer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTrialDialog)
