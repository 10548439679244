import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'

import Typography from '@material-ui/core/Typography'
import StartDateIcon from '@material-ui/icons/Today'
import LocationIcon from '@material-ui/icons/LocationOn'
import DetailsIcon from '@material-ui/icons/AccountBox'
import LeagueMenu from './../components/LeagueMenu'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'

import TeamPage from './../components/pages/Teams'
import SchedulePage from './../components/pages/Schedule'
import SettingsPage from './../components/pages/Settings'
import StandingsPage from './../components/pages/Standings'
import PlayoffsPage from './../components/pages/PlayOffs'
import EPlayoffsPage from './../components/pages/EPlayOffs'
import AddTeamDialog from './../components/forms/AddTeam'
import AddGameDialog from './../components/forms/AddGame'
import BroadcastMessage from './../components/BroadcastMessage'
import HubspotListDialog from 'common/components/HubspotListDialog'
import TournamentPage from './../components/pages/Tournament'
import { colors } from 'consts'

import {
  getLeagueDetails,
  getAgeGroups,
  updateLeagueDetails,
} from './../actions'
import { getNewProducts, getProducts } from './../../product/actions'
import { getFacilities } from './../../common/actions'

class Details extends React.Component {
  componentDidMount() {
    this.props.getLeagueDetails(this.props.match.params.id)
    this.props.getNewProducts()
    this.props.getProducts()
    this.props.getFacilities()
    this.props.getAgeGroups()
  }

  render() {
    const { e_league } = this.props
    console.log(this.props)
    return (
      <div>
        <AddTeamDialog />
        <AddGameDialog />
        <BroadcastMessage />
        <HubspotListDialog />
        {this.props.league && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ paddingTop: '1%' }}
            spacing={4}
          >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h3">{this.props.league.name}</Typography>
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <LocationIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {this.props.league.facility.name}
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <DetailsIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {this.props.league.age_group.name} - {this.props.league.gender}
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <StartDateIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {this.props.league.start_date_parsed} -{' '}
                {this.props.league.end_date_parsed}
              </Typography>
              <br />
              <div style={{display: 'flex', flexDirection:"row", justifyContent:'space-around'}}>
              {this.props.league?.parent_league?.ID && (
                <Link
                  style={{ display: 'flex' }}
                  href={`/league/${this.props.league.parent_league.ID}`}
                >
                  <Button
                    style={{
                      fontSize:12,
                      fontWeight:'bold',
                      cursor: 'pointer',
                      borderRadius: 10,
                      color: 'blue',
                      backgroundColor: colors.purple_mild,
                    }}
                  >
                    Go to League Season
                  </Button>
                </Link>
              )}
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                Closed registration
                <Checkbox
                  checked={this.props.league.force_close}
                  name="closed_registration"
                  onChange={e =>
                    this.props.updateLeagueDetails(
                      'force_close',
                      e.target.checked
                    )
                  }
                />
              </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <LeagueMenu />
            </Grid>
            <Grid item xs={12}>
              {this.props.league_tab === 'teams' &&
                (this.props.is_container ? <TournamentPage /> : <TeamPage />)}
              {this.props.league_tab === 'schedule' && <SchedulePage />}
              {this.props.league_tab === 'settings' && <SettingsPage />}
              {this.props.league_tab === 'standings' && <StandingsPage />}
              {this.props.league_tab === 'playoffs' && e_league && (
                <EPlayoffsPage />
              )}
              {this.props.league_tab === 'playoffs' && !e_league && (
                <PlayoffsPage />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  league: state.league.league,
  league_tab: state.league.league_tab,
  e_league: state.league.league && state.league.league.is_e_league,
  is_container: state.league.league && state.league.league.is_container,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getLeagueDetails: id => dispatch(getLeagueDetails(id)),
  getProducts: () => dispatch(getProducts()),
  getNewProducts: () => dispatch(getNewProducts()),
  getFacilities: () => dispatch(getFacilities()),
  getAgeGroups: () => dispatch(getAgeGroups()),
  updateLeagueDetails: (k, v) => dispatch(updateLeagueDetails(k, v)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
