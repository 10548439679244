import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TeamIcon from '@material-ui/icons/PersonPin'
import CalendarIcon from '@material-ui/icons/DateRange'
import StandingsIcon from '@material-ui/icons/AmpStories'
import Check from '@material-ui/icons/Check'
import Suspend from '@material-ui/icons/RemoveCircleOutline'
import User from '@material-ui/icons/SupervisedUserCircle'

import { changeTab } from './../actions'

class Details extends React.Component {
  render() {
    return (
      <Paper square style={{ marginBottom: '2em' }} elevation={0}>
        <Tabs
          value={this.props.tab}
          onChange={(a, b) => this.props.changeTab(b)}
          variant="scrollable" // Changed from "fullWidth" to "scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          <Tab icon={<TeamIcon />} label="Profile" value="profile" />
          <Tab
            icon={<CalendarIcon />}
            label="Invoice history"
            value="history"
          />
          <Tab icon={<StandingsIcon />} label="Contracts" value="contracts" />
          <Tab icon={<User />} label="Membership" value="memberships" />
          <Tab icon={<Check />} label="Classes" value="classes" />
          <Tab icon={<Check />} label="Free Trials" value="free_trials" />
          <Tab icon={<Suspend />} label="Suspensions" value="suspensions" />
        </Tabs>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.customer.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTab: tab => dispatch(changeTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
