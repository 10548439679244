import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import NameForm from 'common/components/forms/TextEdit'
import LocationIcon from '@material-ui/icons/LocationOn'
import DetailsIcon from '@material-ui/icons/AccountBox'
import StartDateIcon from '@material-ui/icons/Today'
import Link from '@material-ui/core/Link'

import { colors } from 'consts'
import { unixToDateTime, niceNumber } from 'helper'
import { actions } from 'classes/redux'
import { updateName, getFees } from 'classes/actions'
import { getNewProducts } from './../../product/actions'
import { openHubspotList } from 'common/actions'

const styles = theme => ({
  root: {},
  details: {
    display: 'flex',
  },
})

const ContractDetails = ({
  classes,
  classInfo,
  hours,
  getFees,
  changeContractDetails,
  openBroadcast,
  openClone,
  openFeeEditModal,
  updateName,
  openHubspotList,
  products,
}) => {
  let variation = null;
  const product = products.find(p => p.ID === classInfo.new_product_id)
  if (product){
    variation = product.variations.find(p => p.facility_id === classInfo.facility_id)
  }
  if (!variation){
    variation = classInfo.newProduct
    variation = product
  }
  if (classInfo.is_container){
    return <Grid
    container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h3">{classInfo.name}</Typography>
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <LocationIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {classInfo.facility.name}
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <StartDateIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {unixToDateTime(classInfo?.start_date).format('MM/DD/YYYY')} -{' '}
                {unixToDateTime(classInfo?.end_date).format('MM/DD/YYYY')}
              </Typography>
            </Grid>
    </Grid>
  }
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12}>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <div className={classes.details}>
              <div>
                <Typography gutterBottom variant="h5">
                  <NameForm origName={classInfo.name} onSave={updateName} />
                  <Chip
                    size="small"
                    label={<b>{classInfo.facility.name}</b>}
                    style={{
                      //color: colors.purple_strong,
                      backgroundColor: colors.purple_mild,
                      margin: 5,
                    }}
                  />
                </Typography>
              </div>
            </div>
            {classInfo?.class_parent?.ID && (
              <Link
                style={{ padding: 5 }}
                href={`/classes/${classInfo.class_parent.ID}`}
              >
                <Button
                  style={{
                    fontSize:12,
                    fontWeight:'bold',
                    cursor: 'pointer',
                    borderRadius: 10,
                    color: 'blue',
                    backgroundColor: colors.purple_mild,
                  }}
                >
                  Go to Class Season
                </Button>
              </Link>
            )}
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Fee</b>:{' '}
                <span>
                <b>${variation ? variation.base_price||variation.individual_price: classInfo.product.base_price || classInfo.product.individual_price}</b>
                </span>
                <IconButton
                  className={classes.root}
                  onClick={() => {
                    // TODO: Change 1 to 10
                    getFees(classInfo.facility_id)
                    changeContractDetails('fee_id', classInfo.product_id)
                    changeContractDetails(
                      'fee',
                      classInfo.product.individual_price
                    )
                    openFeeEditModal()
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Typography>
            </div>
            <div className={classes.details}>
              <Typography variant="body1">
                <b>Event type</b>: <span>Class</span>
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Quota</b>: <span>{classInfo.max_kids} kids/class</span>
              </Typography>
            </div>
            <div style={{ marginTop: '15px' }}>
            <Button
              className="save-btn"
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={() => openClone()}
            >
              Clone
            </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader subheader="Events" style={{ paddingBottom: 0 }} />
          <CardContent>
            {classInfo.events.length ? (
              <>
                <div className={classes.details}>
                  <Typography variant="body1">
                    <b>Start</b>:{' '}
                    {unixToDateTime(classInfo.events[0].start_date).format(
                      'M/D/YYYY h:mmA'
                    )}
                  </Typography>
                </div>
                <div className={classes.details} style={{ marginTop: 10 }}>
                  <Typography variant="body1">
                    <b>End</b>:{' '}
                    {unixToDateTime(
                      classInfo.events[classInfo.events.length - 1].end_date
                    ).format('M/D/YYYY h:mmA')}
                  </Typography>
                </div>
              </>
            ) : null}
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Events</b>: {classInfo.events.length} ({hours} hours)
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader subheader="Invoice" style={{ paddingBottom: 0 }} />
          <CardContent>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Total Invoiced</b>: $
                {niceNumber(
                  classInfo.attendees.reduce(
                    (a, b) => a + b.invoice.invoiced,
                    0
                  )
                )}{' '}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Total paid</b>: $
                {niceNumber(
                  classInfo.attendees.reduce((a, b) => a + b.invoice.paid, 0)
                )}{' '}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Balance</b>: $
                {niceNumber(
                  classInfo.attendees.reduce((a, b) => a + b.invoice.balance, 0)
                )}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardContent>
            <Button
              className="save-btn"
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={() => openBroadcast()}
            >
              Broadcast Message
            </Button>
            <Button
              className="save-btn"
              style={{
                cursor: 'pointer',
                width: '100%',
                marginTop: 20,
              }}
              onClick={() => openHubspotList(classInfo.ID)}
            >
              Create hubspot list
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  classInfo: state.classes.classInfo,
  products: state.product.newProducts,
  hours:
    Math.round(
      state.classes.classInfo.events.reduce(
        (a, b) => a + (parseInt(b.end_date) - parseInt(b.start_date)) / 3600,
        0
      ) * 10
    ) / 10,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openFeeEditModal: () => dispatch(actions.toggleFeeModal({ open: true })),
  getFees: facility_id => dispatch(getFees(10, facility_id)),
  changeContractDetails: (k, v) =>
    dispatch(actions.changeContractDetails({ field: k, value: v })),
  updateName: name => dispatch(updateName(name)),
  openBroadcast: () => dispatch(actions.updateBroadcastMessage({ open: true })),
  openClone: () => dispatch(actions.updateClone({ open: true })),
  openQuotaEditModal: quota =>
    dispatch(actions.toggleQuotaEdit({ open: true, quota })),
  openHubspotList: class_id =>
    dispatch(openHubspotList({ class_ids: [class_id] })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContractDetails))
