import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import SearchBar from './../components/SearchBar'
import CustomerTable from './../components/CustomerTable'
import CreateCustomerDialog from './../components/CreateCustomerDialog'
import ReactivateCustomerDialog from './../components/ReactivateCustomerDialog'

class SearchCustomer extends React.Component {
  render() {
    return (
      <div>
        <CreateCustomerDialog />
        <ReactivateCustomerDialog />
        <Paper elevation={0}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ paddingTop: '2%' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <SearchBar />
            </Grid>
            <Grid item xs={12}>
              <CustomerTable />
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomer)
