import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Link from '@material-ui/core/Link'
import CSVReader from 'react-csv-reader'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {  reactivateCustomer, searchDeletedCustomers } from './../actions'
import { actions } from './../redux'
const { selectDeletedCustomer} = actions

class ReactivateCustomerDialog extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
            Reactivate customer
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={this.props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
            style={{ minWidth: '600px' }}
            >
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            options={this.props.customers}
            getOptionLabel={option => `${option.first_name} ${option.last_name} (${option.email})`}
            renderInput={params => (
              <TextField
                {...params}
                label="Customer"
                variant="outlined"
                fullWidth
                onChange={e => this.props.searchDeletedCustomers(e.target.value)}
              />
            )}
            value={this.props.customer}
            onChange={(e, c) => this.props.selectDeletedCustomer(c)}
          />
        </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.reactivateCustomer()}
            color="primary"
          >
            Reactivate
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.customer.customer_reactivate_modal_open,
  ...state.customer.reactivate,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleCustomerReactivateModal({ open: false })),
  searchDeletedCustomers: q => dispatch(searchDeletedCustomers(q)),
  selectDeletedCustomer: value => dispatch(selectDeletedCustomer(value)),
  reactivateCustomer: () => dispatch(reactivateCustomer()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactivateCustomerDialog)
