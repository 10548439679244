import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Open from '@material-ui/icons/OpenInBrowser'

import { niceNumber, exportToCSV, unixToDate } from 'helper'
import { colors } from 'consts'
import { changeLoading } from 'common/actions'

const Classes = props => {
  const { classes} = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Start Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  End Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {classes.map(item => (
                <TableRow key={item.ID}>
                  <TableCell style={{ textAlign: 'center' }}>
                    {item.name}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {item.start_date_parsed}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {item.end_date_parsed}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                      <Link
                        href={`/classes/${item.ID}`}
                      >
                        <IconButton style={{ padding: 1 }}>
                          <Open
                            fontSize="small"
                            style={{
                              color: colors.blue_strong,
                            }}
                          />
                        </IconButton>
                      </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
    const classes = state.customer.classes?.map(item => {
        return {
        ...item,
        start_date_parsed: unixToDate(item.start_date).format('MM/DD/YYYY'),
        end_date_parsed: unixToDate(item.end_date).format('MM/DD/YYYY'),
        }
  })
 
  return {
    classes,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLoading: loading => dispatch(dispatch(changeLoading(loading))),
})

export default connect(mapStateToProps, mapDispatchToProps)(Classes)
