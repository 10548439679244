import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TeamIcon from '@material-ui/icons/PersonPin'
import InvoicesIcon from '@material-ui/icons/Receipt'
import HistoryIcon from '@material-ui/icons/History'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import SportsIcon from '@material-ui/icons/Sports'
import CheckIcon from '@material-ui/icons/Check'

import { actions } from './../redux'

class Menu extends React.Component {
  render() {
    return (
      <Paper square elevation={0}>
        <Tabs
          value={this.props.tab_menu}
          onChange={(a, b) => this.props.changeTabMenu(b)}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          {this.props.is_league ? (
            <Tab icon={<SportsIcon />} label="League" value="league" />
          ) : (
            <Tab icon={<InvoicesIcon />} label="Invoice" value="invoice" />
          )}
          <Tab
            icon={<AccessibilityNewIcon />}
            label="Health Check"
            value="health_check"
          />
          {this.props.is_soccernaut && (
            <Tab icon={<TeamIcon />} label="Soccernaut" value="soccernaut" />
          )}
          {this.props.is_pickup && (
            <Tab icon={<TeamIcon />} label="Pickup" value="pickup" />
          )}
          {this.props.is_manager && (
            <Tab icon={<HistoryIcon />} label="Changes" value="changes" />
          )}
          <Tab icon={<CheckIcon />} label="Attendance" value="attendance" />
        </Tabs>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tab_menu: state.calendar.tab_menu,
  is_manager:
    state.user.user_type === 'manager' || state.user.user_type === 'admin',
  is_soccernaut: state.calendar.event_details.product_type_id === 14,
  is_pickup: state.calendar.event_details.product_type_id === 5,
  attendances: state.calendar.event_details.attendances || [],
  is_league: state.calendar.event_details.match_id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTabMenu: tab => dispatch(actions.changeTabMenu({ tab })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
