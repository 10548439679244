import React, { useState } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import moment from 'moment'

import { getXClasses } from './../actions'
import { actions } from './../redux'
import { unixToDate, unixToDateTime, momentDateTimeSecondsToUnix } from 'helper'
import { colors } from 'consts'

const ResourceDropdown = ({
  query,
  changeFilterDetails,
  start_date_parsed,
  end_date_parsed,
  start_date,
  end_date,
  getPickupPlayers,
  facilities,
  facility_id,
  toggleCreationModal,
  is_admin,
  getClasses,
}) => {
  const [focusedInput, setFocusedInput] = useState(null)

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={4} style={{ width: '100%' }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Filter by Name"
            value={query}
            onChange={e => changeFilterDetails('query', e.target.value)}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={facilities}
            placeholder="Facility"
            onChange={v => changeFilterDetails('facility_id', v ? v : [])}
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isMulti
          />
        </Grid>
        <Grid item xs={3} style={{
            display:'flex',
            flexDirection:'row',
          }}>
          <DatePickerComponent
            id="start_date"
            placeholder="Start date"
            selectedValue={start_date ? unixToDateTime(start_date) : null}
            onChange={e => {
              const selectedStartDate = momentDateTimeSecondsToUnix(moment(e.value).hour(0).minute(0).second(0));
              changeFilterDetails(
                'start_date',
                selectedStartDate
              );
            }}
            style={{ textAlign: 'center' }}
          />

          <DatePickerComponent
            id="end_date"
            placeholder="End date"
            selectedValue={end_date ? unixToDateTime(end_date) : null}
            onChange={e => {
              const selectedEndDate = momentDateTimeSecondsToUnix(moment(e.value).hours(23).minutes(59).seconds(59));
              changeFilterDetails(
                'end_date',
                selectedEndDate
              );
            }}
            style={{ textAlign: 'center' }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            style={{
              color: colors.blue_strong,
              backgroundColor: colors.blue_mild,
            }}
            component="span"
            onClick={getClasses}
          >
            Search
          </Button>
          {is_admin && (
            <Button
              component="span"
              variant="outlined"
              color="secondary"
              onClick={() => toggleCreationModal()}
            >
              Create
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.classes.filters,
  facilities: state.common.filter_facilities,
  start_date_parsed: unixToDate(state.pickup.filter.start_date),
  end_date_parsed: unixToDate(state.pickup.filter.end_date),
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilterDetails: (k, v) =>
    dispatch(actions.changeFilterDetails({ key: k, value: v })),
  toggleCreationModal: () =>
    dispatch(actions.toggleCreationModal({ open: true })),
  getClasses: () => {
    dispatch(actions.setSearchOffset({ offset: 0 }))
    dispatch(actions.resetPagination({}))
    dispatch(getXClasses())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
