import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import Checkbox from '@material-ui/core/Checkbox'

import HubspotListDialog from 'common/components/HubspotListDialog'

import { colors } from 'consts'
import { niceNumber } from 'helper'
import { deleteClass, getXClasses } from '../actions'
import { openDeleteModal, openHubspotList } from 'common/actions'
import { actions } from '../redux'

const sortIcon = <ArrowDownward />

const columns = memoize((checked, toggleChecked, deleteClass) => [
  {
    name: 'x',
    selector: 'x',
    center: true,
    cell: row => (
      <Checkbox
        checked={checked[row.ID]}
        onChange={e => toggleChecked(row.ID, e.target.checked)}
        name="hubspot"
      />
    ),
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    center: true,
    grow: 5,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.name}
        <br />
        {row.facility.name}
      </div>
    ),
  },
  {
    name: 'Dates',
    selector: 'start_date',
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.start_date_parsed} - {row.end_date_parsed}
      </div>
    ),
    sortFunction: (rowA, rowB) => rowA.start_date - rowB.start_date,
  },
  {
    name: 'Participants',
    selector: 'participants',
    sortable: true,
    center: true,
    format: row => row.attendees.length,
    sortFunction: (rowA, rowB) => rowA.attendees.length - rowB.attendees.length,
  },
  {
    name: 'Invoiced',
    selector: 'invoiced',
    center: true,
    grow: 2,
    cell: row =>
      niceNumber(row.attendees.reduce((a, b) => a + b.invoice?.invoiced, 0)),
    sortFunction: (rowA, rowB) => {
      const invoiceA = rowA.attendees.reduce(
        (a, b) => a + b.invoice?.invoiced,
        0
      )
      const invoiceB = rowB.attendees.reduce(
        (a, b) => a + b.invoice?.invoiced,
        0
      )
      return invoiceA - invoiceB
    },
  },
  {
    name: 'Paid',
    selector: 'paid',
    center: true,
    grow: 2,
    cell: row =>
      niceNumber( row.attendees.reduce((a, b) => a + b.invoice?.paid, 0)),
    sortFunction: (rowA, rowB) => {
      const paidA = rowA.attendees.reduce((a, b) => a + b.invoice?.paid, 0)
      const paidB = rowB.attendees.reduce((a, b) => a + b.invoice?.paid, 0)
      return paidA - paidB
    },
  },
  {
    name: 'Balance',
    selector: 'balance',
    center: true,
    grow: 2,
    cell: row =>
      niceNumber(row.attendees.reduce((a, b) => a + b.invoice?.balance, 0)),
    sortFunction: (rowA, rowB) => {
      const balanceA = rowA.attendees.reduce((a, b) => a + b.invoice?.balance, 0)
      const balanceB = rowB.attendees.reduce((a, b) => a + b.invoice?.balance, 0)
      return balanceA - balanceB
    },
  },
  {
    name: 'Actions',
    selector: 'actions',
    center: true,
    cell: row => (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
        }}
      >
        <Link href={`/classes/${row.ID}`} target="_blank">
          <IconButton>
            <OpenInNewIcon
              fontSize="small"
              className="save-btn"
              style={{
                backgroundColor: 'transparent',
                color: colors.blue_strong,
              }}
            />
          </IconButton>
        </Link>
        <IconButton onClick={() => deleteClass(row.ID)}>
          <DeleteIcon fontSize="small" style={{ color: colors.red_strong }} />
        </IconButton>
      </div>
    ),
  },
])

const customStyles = {
  rows: {
    style: {
      minHeight: '75px',
    },
  },
}
const ClassesTable = ({ classes, nb_classes_total, search_limit, deleteClass, openHubspotList, getXClasses, changeSearchLimit, changeSearchOffset, resetPaginationVariable, resetPagination }) => {
  const [checked, setChecked] = React.useState({})
  const toggleChecked = (id, isChecked) => {
    if (isChecked) {
      setChecked({ ...checked, [id]: true })
    } else {
      setChecked({ ...checked, [id]: false })
    }
  }
  const handleChangingPage = (page) => {
    changeSearchOffset((page-1)*search_limit)
    getXClasses()
  }

  return (
    <div>
      <HubspotListDialog />
      Totaling <b>{classes?.reduce((a, b) => a + b.attendees.length, 0)}</b>{' '}
      participants.
      <br />
      <Button
        onClick={() =>
          openHubspotList(Object.keys(checked).filter(k => checked[k]))
        }
      >
        Export hubspot
      </Button>
      <Paper elevation={0}>
        <DataTable
          title="Classes"
          columns={columns(checked, toggleChecked, deleteClass)}
          data={classes}
          highlightOnHover
          defaultSortField="date"
          defaultSortAsc={false}
          sortIcon={sortIcon}
          pagination
          striped
          fixedHeader
          paginationServer={true}
          paginationResetDefaultPage={resetPaginationVariable}
          paginationPerPage={search_limit}
          paginationRowsPerPageOptions={[1, 10, 25, 50, 100]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => 
            {changeSearchLimit(currentRowsPerPage);
              resetPagination();
              if (currentPage === 1) {
                handleChangingPage(currentPage);
              }
            }}
          paginationTotalRows={nb_classes_total}
          customStyles={customStyles}
          onChangePage={(page, _) => handleChangingPage(page)}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const filters = state.classes.filters
  const query = filters.query && filters.query.trim().toLowerCase()
  return {
    classes: state.classes.classes.filter(c => {
      if (query) return c.name.toLowerCase().indexOf(query) !== -1
      return true
    }),
    nb_classes_total: state.classes.nb_classes_total,
    search_limit: state.classes.search_limit,
    resetPaginationVariable: state.classes.resetPagination,
  }
}

const mapDispatchToProps = dispatch => ({
  deleteClass: id => dispatch(openDeleteModal('class', () => deleteClass(id))),
  openHubspotList: class_ids => dispatch(openHubspotList({ class_ids })),
  getXClasses: () => dispatch(getXClasses()),
  changeSearchLimit: limit => dispatch(actions.setSearchLimit({limit: limit})),
  changeSearchOffset: limit => dispatch(actions.setSearchOffset({offset: limit})),
  resetPagination: () => dispatch(actions.resetPagination({})),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassesTable)
