import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import CustomerSection from './CustomerSection'
import ProductFieldTimeForm from './ProductFieldTimeSection'
import MatchSection from './MatchSection'
import RepeatForm from 'calendar/components/forms/RepeatSection'

class CreateEventForm extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={6}>
          <ProductFieldTimeForm />
          {[13, 19].indexOf(this.props.product_type_id) !== -1 && (
            <RepeatForm />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              {this.props.product_type_object.category != 'block' && (
                <CustomerSection />
              )}
              {this.props.match && <MatchSection />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.calendar.event_details,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventForm)
